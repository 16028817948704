import { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'
import { bindMethod } from 'helpers/bind'
import { debounce } from 'lodash-es'
import Component from 'navigation/component/Component'
import mqStore from 'stores/mqStore'
import './ShowLooks.scss'
import store from 'stores'

type ShowLooksType = {
  refs: {
    lookItems: HTMLElement[]
    looksCount:HTMLElement
    popinLooks: HTMLElement[]
    popinLooksInfo: HTMLElement[]
    slider: HTMLElement
    popinLooksProductsButton: HTMLElement[]
    popinLooksProductsPanel: HTMLElement[]
    popinLooksProductsOverlay: HTMLElement
    showLooksMore: HTMLElement
    showLooksViewMore: HTMLButtonElement
  }
}

class ShowLooks extends Component<ShowLooksType> {
  private popinLooksIndex = null

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    this.bindModules()
    if (window.location.hash && window.location.hash.includes('look='))
      this.openLook(parseInt(window.location.hash.split('look=')[1]))
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap()
    }
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.lookItems.forEach(item => {
      item[method]('click', this.handleLookItemClick)
    })
    mqStore.tabletPortrait.toggleListener(add, () => {
      this.bindModules()
    })
    this.refs.slider[method]('scroll', this.onScroll)
    this.refs.popinLooksProductsButton?.forEach(button => {
      button[method]('click', this.handleMobileProducts)
    })
    this.refs.popinLooksProductsOverlay[method]('click', this.closeProductsPanel)
    this.refs.showLooksViewMore?.[method]('click', this.showMoreLooks)
  }

  showMoreLooks = (): void => {
    this.refs.showLooksViewMore.parentElement?.classList.toggle('hidden')
    this.refs.showLooksMore.classList.toggle('hidden')
  }

  onScroll = (): void => {
    const options = {
      root: this.refs.slider,
      rootMargin: '0px',
      threshold: mqStore.tabletPortrait.get() ? 0.5 : 0.2
    }

    const handleIntersection = debounce((entries, observer) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const index = entry.target.dataset.index
          this.emit('change', parseInt(index) + 1)
          window.location.hash = `look=${parseInt(index) + 1}`

          if (index !== this.popinLooksIndex) {
            this.popinLooksIndex = index
            this.refs.popinLooksInfo.forEach(info => {
              info.classList.add('hidden')
            })
            this.refs.popinLooksInfo[index].classList.remove('hidden')
          }
        }
      })
    }, 0)

    const observer = new IntersectionObserver(handleIntersection, options)

    this.refs.popinLooks.forEach(image => {
      observer.observe(image)
    })
  }

  handleLookItemClick = (e: Event) => {
    const itemClicked = e.currentTarget as HTMLElement
    const index = parseInt(itemClicked.dataset.index!, 10)
    this.openLook(index + 1)
  }

  openLook = (index:number) => {
    this.parent?.modules.showSubheader?.refs.closePopin?.classList.remove('hidden')
    this.parent?.modules.showSubheader?.refs?.horizontalNav?.classList.add('hidden')
    this.emit('change', index)

    store.headerCollapsed.set(true)
    if (scroll.scrollTop() <= 100) scroll.scrollTo(200, { duration: 0 })
    // scroll.lock()
    this.parent?.el.classList.add('show-popin')
    window.location.hash = `look=${index}`
    this.emit('change', index)

    const scrollTarget = this.refs.popinLooks[index - 1].offsetTop
    this.refs.slider.scrollTo({
      top: scrollTarget,
      behavior: 'instant'
    })

    this.bindModules()
  }

  handleMobileProducts = (e: Event) => {
    this.el.classList.add('open-products')
  }

  closeProductsPanel = (): void => {
    this.el.classList.remove('open-products')
  }
}

export default ShowLooks
