import Component from 'navigation/component/Component'
import './ShowGuests.scss'
import { ModulesMappping } from 'core/modulesMap'
import InfiniteCarousel from 'components/infinite/InfiniteCarousel'

export default class ShowGuests extends Component {
  getModulesMap (): ModulesMappping {
    return {
      slider: ['.show__guests__items__inner', InfiniteCarousel]
    }
  }
}
