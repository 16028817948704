import { ModuleMapping } from 'core/modulesMap'
import MainPage from 'navigation/pages/MainPage'
import { bindEmitterMethod } from 'helpers/bind'

import ShowSubheader from '../show-subheader/ShowSubheader'
import ShowHero from '../show-hero/ShowHero'
import ShowDescription from '../show-description/ShowDescription'
import ShowLooks from '../show-looks/ShowLooks'
import ShowGuests from '../show-guests/ShowGuests'
type ShowRefs = {
  refs: {
    looksCount: HTMLElement
    lookItems: HTMLElement[]
  }
  modules:{
    showLooks: ShowLooks
    showSubheader: ShowSubheader
  }
}
export default class Show extends MainPage<ShowRefs> {
  initialized (): void {
    super.initialized()
    if (this.refs.looksCount) this.refs.looksCount.innerText = this.refs.lookItems.length.toString()
  }

  getModulesMap (): { [x: string]: ModuleMapping } {
    return {
      showSubheader: ['section.show-subheader', ShowSubheader],
      showHero: ['section.show-hero', ShowHero],
      showDescription: ['section.show-description', ShowDescription],
      showLooks: ['section.show-looks', ShowLooks],
      showGuests: ['section.show-guests', ShowGuests]
    }
  }

  bindEvents (add:boolean): void {
    const emitterMethod = bindEmitterMethod(add)
    this.modules.showLooks[emitterMethod]('change', this.handleLookChange)
  }

  handleLookChange = (look:number|null) => {
    this.modules.showSubheader.lookIndex = look
  }
}
