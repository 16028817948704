import './ShowSubheader.scss'
import { bindMethod } from 'helpers/bind'
import scroll from 'core/scroll'
import { ModulesMappping } from 'core/modulesMap'
import Component from 'navigation/component/Component'
import store from 'stores'

type ShowSubheaderType = {
  refs: {
    closePopin: HTMLButtonElement
    horizontalNav: HTMLElement
    breadcrumbs: HTMLElement
  }
}

class ShowSubheader extends Component<ShowSubheaderType> {
  private _lookIndex:number|null = null
  public get lookIndex () {
    return this.lookIndex
  }

  public set lookIndex (value:number|null) {
    if (value === null) {
      this.refs.breadcrumbs.lastElementChild?.remove()
      this._lookIndex = value
      return
    }

    if (this._lookIndex) {
      if (this.refs.breadcrumbs.lastElementChild)
        this.refs.breadcrumbs.lastElementChild.innerHTML = `Look ${value.toString().padStart(2, '0')}`
    } else {
      const breadcrumb = document.createElement('div')
      breadcrumb.classList.add('breadcrumb__item')
      breadcrumb.innerText = `Look  ${value.toString().padStart(2, '0')}`
      this.refs.breadcrumbs.append(breadcrumb)
    }

    this._lookIndex = value
  }

  constructor (el: HTMLElement) {
    super(el)
    this.bindRefs()
  }

  initialized (): void {
    super.initialized()
    console.log(this.el)
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap()
    }
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)
    this.refs.closePopin[method]('click', this.handleClosePopin)
    window[method]('keydown', this.handleKeyDown)
  }

  handleKeyDown = (e:Event) => {
    if ((e as KeyboardEvent).code === 'Escape')
      this.handleClosePopin()
  }

  handleClosePopin = () => {
    if (this._lookIndex === null) return
    scroll.unlock(true)
    this.parent?.el?.classList.remove('show-popin')
    this.refs.closePopin?.classList.add('hidden')
    this.refs.horizontalNav?.classList.remove('hidden')
    this.lookIndex = null
    store.headerCollapsed.set(false)
    window.location.hash = ''

    this.bindModules()
  }
}

export default ShowSubheader
