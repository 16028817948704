import HomeMedia from 'components/home-media/HomeMedia'
import config from 'core/config'
import { ModulesMappping } from 'core/modulesMap'
import scroll from 'core/scroll'
import { bindMethod } from 'helpers/bind'
import MainPage from 'navigation/pages/MainPage'
import store from 'stores'
import sizeStore from 'stores/sizeStore'

type HomeType = {
  modules : {
  },
}

class Home extends MainPage<HomeType> {
  declare bounds: { top: number, bottom: number, target: HTMLElement }[]

  constructor (...args: ConstructorParameters<typeof MainPage>) {
    super(...args)
    this.resize()
    this.scroll()

    store.headerTransparent.set(true)
    // store.headerBig.set(config.home.bigLogo)
  }

  getModulesMap (): ModulesMappping {
    return {
      ...super.getModulesMap(),
      homeMedia: ['.home-media', HomeMedia]
    }
  }

  bindEvents (add?: boolean): void {
    const method = bindMethod(add)
    document[method]('scroll', this.scroll)
  }

  scroll = () => {
    const screenEdge = scroll.scrollTop() + sizeStore.headerHeight.get() / 2

    const currentBlock = this.bounds.find((b, index) => {
      const top = index === 0 ? 0 : b.top
      return top <= screenEdge && b.bottom > screenEdge
    })

    const blockColor =
      currentBlock?.target
        .querySelector('.home-media__medias')
        ?.children[0]
        ?.getAttribute('header-color')

    if (blockColor)
      store.headerColor.set(blockColor as 'black' | 'white')
  }

  show (nextPage?: string): Promise<void> {
    return super.show(nextPage)
  }

  hide (nextPage?: string): Promise<void> {
    store.headerColor.set('black')
    store.headerBig.set(false)
    store.headerCollapsed.set(false)
    store.headerTransparent.set(false)

    return super.hide(nextPage)
  }

  resize (): void {
    const modulesMap = this.getModulesMap()
    const elements = [
      ...this.el.querySelectorAll(modulesMap.homeMedia[0]) || []
    ]

    this.bounds = elements.map(e => {
      return {
        target: e as HTMLElement,
        top: e.getBoundingClientRect().top + scroll.scrollTop(),
        bottom: e.getBoundingClientRect().bottom + scroll.scrollTop()
      }
    })

    super.resize()
  }
}

Home.pageName = 'Home'

export default Home
